
import { Modal } from 'bootstrap';
import { defineComponent, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { SITE_PRODUCTS } from '../graphql/Queries';
import { Apollo, Notify } from '@/core/services';
import { ADD_SUBSCRIPTION_PRODUCT } from '../graphql/Mutations';
import { Actions } from '@/store/enums/StoreEnums';
import Table from '../../../components/Table/Table.vue';
import InnerLoader from '../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'Show all products',
    components: { Table, InnerLoader },
    setup() {
        const modal: any = ref();
        const storeHandler = useStore();
        const loader = ref(false);
        const i18n = useI18n();
        const system_locale = ref();
        const number = ref([]) as Record<any, any>;
        const products = ref([]) as Record<any, any>;
        const cartLimits = ref([]) as Record<any, any>;
        const order_subscription_id = ref([]);
        const columns = ref([
            {
                label: '',
                key: 'image'
            },
            {
                label: 'message.PRODUCT_NAME',
                key: 'name'
            },
            {
                label: 'message.PRICE',
                key: 'price',
                textAlignment: 'end'
            },
            {
                label: 'message.QUANTITY',
                key: 'quantity'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
        });

        const appInstance = storeHandler.getters.appInstance;

        const Products = id => {
            loader.value = true;
            Apollo.watchQuery({
                query: SITE_PRODUCTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    order_subscription_id: id
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                loader.value = false;
                const response = JSON.parse(data.site_products);
                products.value = response;
                products.value.forEach((ele, index) => {
                    if (ele.multiple_limit != null && ele.multiples != null) {
                        number.value[index] = ele.multiples;
                    } else {
                        number.value[index] = 1;
                    }
                });
            });
        };

        const cartLimit = (multipleLimit, multiple) => {
            cartLimits.value = [];
            const limit = multipleLimit / multiple;
            let res = 0;
            for (let i = 1; i <= limit; i++) {
                res = multiple + res;
                cartLimits.value.push(res);
            }
        };

        const show = id => {
            Products(id);
            order_subscription_id.value = id;
            modal.value = new Modal(document.getElementById('product_modal'));
            modal.value.show();
        };

        const AddProduct = async (index, products) => {
            try {
                loader.value = true;
                await Apollo.mutate({
                    mutation: ADD_SUBSCRIPTION_PRODUCT,
                    variables: {
                        product_id: products.id,
                        quantity: number.value[index],
                        multiple: products.multiple_limit == null && products.multiples == null ? false : true,
                        order_subscription_id: order_subscription_id.value
                    },
                    update: (store, { data: { subscription_product } }) => {
                        const response = JSON.parse(subscription_product);
                        storeHandler.dispatch(Actions.SET_SUBSCRIPTION, response);
                        modal.value.hide();
                        loader.value = false;
                        Notify.success(`${i18n.t('message.SUBSCRIPTION_PRODUCT_ADDED_SUCCESSFULLY')}`);
                    }
                });
            } catch ($e) {
                loader.value = false;
            }
        };

        const inquiryProduct = () => {
            appInstance.$messageBox
                .confirm(`${i18n.t('message.PRODUCT_UNAVAILABILITY_MESSAGE')}`, i18n.t('message.INFO'), {
                    confirmTextButton: i18n.t('message.OK'),
                    cancelTextButton: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = false;
                });
        };

        return {
            system_locale,
            products,
            number,
            cartLimits,
            columns,
            loader,
            inquiryProduct,
            show,
            cartLimit,
            AddProduct
        };
    }
});
