
import { defineComponent, onMounted, ref, watchEffect, computed, inject } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { Apollo, Notify } from "@/core/services";
import { useStore } from "vuex";
import { ORDER_SUBSCRIPTION_DETAILS } from "@/modules/subscription/graphql/Queries";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import SubscriptionProducts from "./SubscriptionProducts.vue";
import Table from "../../../components/Table/Table.vue";
import { DELETE_SUBSCRIPTION_PRODUCT, UPDATE_SUBSCRIPTION_PRODUCT } from "../graphql/Mutations";
// import InnerLoader from "../../../components/InnerLoader.vue";
import SubscriptionLoader from "./SubscriptionLoader.vue";

export default defineComponent({
	name: "Subscription",
	components: {
		SubscriptionProducts,
		Table,
		// InnerLoader,
		SubscriptionLoader,
	},
	setup() {
		const response = ref({}) as Record<any, any>;
		const router = useRouter();
		const route = useRoute();
		const storeHandler = useStore();
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const subscription_details = ref({}) as Record<any, any>;
		const order_products = ref({}) as Record<any, any>;
		const price_tax = ref(0);
		const system_locale = ref();
		const order_subscription_history = ref({}) as Record<any, any>;
		const loader = ref(false);
		const productLists = ref<null | HTMLFormElement>(null);
		const cartLimits = ref([]) as Record<any, any>;
		const columns = ref([
			{
				label: "",
				key: "image",
			},
			{
				label: "message.PRODUCT_NAME",
				key: "name",
			},
			{
				label: "message.PRICE",
				key: "price",
				textAlignment: "end",
			},
			{
				label: "message.QUANTITY",
				key: "quantity",
			},
			{
				label: "message.TOTAL",
				key: "total",
				textAlignment: "end",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);
		const columns_order = ref([
			{
				label: "message.ORDER_ID",
				key: "id",
			},
			{
				label: "message.ORDER_DATE",
				key: "order_date",
			},
			{
				label: "message.STATUS",
				key: "status",
			},
			{
				label: "message.ECONOMIC",
				key: "economic",
			},
			{
				label: "message.SHIPMENT_STATUS",
				key: "shipment_status",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);

		const subscribeData = computed(() => {
			return storeHandler.getters?.getAllSubscription;
		});

		watchEffect(() => {
			system_locale.value = i18n.fallbackLocale.value;
			emitter.emit('setPageHeader', response.value?.order_subscription?.order_discounts?.discount?.discount_type)
		});

		const subscriptionDetails = (uuid) => {
			loader.value = true;
			Apollo.watchQuery({
				query: ORDER_SUBSCRIPTION_DETAILS,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				variables: {
					uuid: uuid,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				loader.value = false;
				response.value = JSON.parse(data.order_subscription_details);
				storeHandler.dispatch(Actions.SET_SUBSCRIPTION, response);
			});
		};

		onMounted(async() => {
			await subscriptionDetails(route.params.uuid);
			
			
		});
		const handleSubscriptionDelete = (subscription) => {
			storeHandler.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					await Apollo.mutate({
						mutation: DELETE_SUBSCRIPTION_PRODUCT,
						variables: {
							order_subscription_id: subscription.order_subscription_id,
							product_id: subscription.product_id,
						},
						update: (store, { data: { delete_subscription_product } }) => {
							const response = JSON.parse(delete_subscription_product);
							storeHandler.dispatch(Actions.SET_SUBSCRIPTION, response);
							loader.value = false;
						},
					});
					Notify.success(i18n.t("message.SUBSCRIPTION_PRODUCT_DELETE_SUCCESSFULLY"));
				})
				.catch(() => {
					loader.value = false;
				});
		};

		const cartLimit = (multipleLimit, multiple) => {
			cartLimits.value = [];
			const limit = multipleLimit / multiple;
			let res = 0;
			for (let i = 1; i <= limit; i++) {
				res = multiple + res;
				cartLimits.value.push(res);
			}
		};

		const handleChange = async (quantity, product_id, order_subscription_id) => {
			try {
				loader.value = true;
				await Apollo.mutate({
					mutation: UPDATE_SUBSCRIPTION_PRODUCT,
					variables: {
						product_id: product_id,
						quantity: quantity,
						multiple: true,
						order_subscription_id: order_subscription_id,
					},
					update: (store, { data: { update_subscription_product } }) => {
						const response = JSON.parse(update_subscription_product);
						storeHandler.dispatch(Actions.SET_SUBSCRIPTION, response);
						Notify.success(i18n.t("message.SUBSCRIPTION_PRODUCT_UPDATED_SUCCESSFULLY"));
						loader.value = false;
					},
				});
			} catch ($e) {
				loader.value = false;
			}
		};

		emitter.on("openSubscriptionProductModel", () => {
			productLists.value?.show(subscribeData.value?.order_subscription?.id);
		});

		const handleView = (order: Record<any, any>) => {
			router.push({
				name: "order_details",
				params: { uuid: order?.current_order?.uuid },
			});
		};

		return {
			subscription_details,
			order_subscription_history,
			order_products,
			price_tax,
			system_locale,
			subscribeData,
			productLists,
			loader,
			cartLimits,
			columns,
			columns_order,
			handleView,
			handleSubscriptionDelete,
			cartLimit,
			handleChange,
		};
	},
});
